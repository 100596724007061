import { PrevRouteService } from './shared/services/misc/prev-route.service';
import { Component, EnvironmentInjector, inject } from '@angular/core';
import { IonicModule, NavController } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { TitleService } from './shared/services/misc/title.service';
import { AuthService } from './shared/services/auth.service';
import { LoggerService } from './shared/services/misc/logger.service';
import { register } from 'swiper/element';
import { collection, FBaseService } from './shared/services/firebase/f-base.service';
import { firstValueFrom } from 'rxjs';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule],
})
export class AppComponent {
  public environmentInjector = inject(EnvironmentInjector);

  constructor(
    private titleService: TitleService,
    private prevRouteService: PrevRouteService,
    private authService: AuthService,
    private logger: LoggerService,
    private navController: NavController,
    private firebaseService: FBaseService
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    this.titleService.refreshTitle();
    this.prevRouteService.loadRouting();
    this.authService.currentUser().subscribe(
      async user => {
        if (user) {
          this.logger.setEmail(user.email);
          this.logger.setUserId(user.uid);
          const userDoc = await firstValueFrom(this.firebaseService.getById(collection.users, user.uid));
          if (userDoc.role === 'TEACHER') {
            if (window.location.pathname.includes('login')) {
              this.navController.navigateRoot('/');
            }
          }
        } else {
          if (this.logger.getUserId() !== null) {
            window.location.href = 'login';
          }
        }
      },
      error => {
        this.logger.handleError(error);
      }
    );
  }
}
