import { Injectable, inject } from '@angular/core';
import { AlertController, LoadingController, Platform, ToastController } from '@ionic/angular';
import { Analytics, logEvent, setUserId } from '@angular/fire/analytics';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { TranslatePipe } from 'src/app/shared/translate/pipes/translate.pipe';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

export class LogItem {
  id: any;
  title: any;
  subtitle: any;
  type: any;
  uid: any;
  versionNumber: any;
  versionCode: any;
  packageName: any;
  cordova: any;
  platform: any;
  timeStamp: any;
  email: any;
  currentUrl: any;
  appName: any;
  constructor(id = null, title = null, subtitle = null, type = null, uid = null, versionNumber = null, versionCode = null, packageName = null, cordova = false, platform = null, timeStamp = null, email = null, currentUrl = null, appName = null) {
    this.id = id;
    this.title = title ? (typeof title !== 'string' ? JSON.stringify(title) : title) : title;
    this.subtitle = subtitle ? (typeof subtitle !== 'string' ? JSON.stringify(subtitle) : subtitle) : subtitle;
    this.type = type;
    this.uid = uid;
    this.versionNumber = versionNumber;
    this.versionCode = versionCode;
    this.packageName = packageName;
    this.cordova = cordova;
    this.platform = platform;
    this.timeStamp = timeStamp || new Date().toISOString();
    this.email = email;
    this.currentUrl = currentUrl;
    this.appName = appName;
  }
}

@Injectable({ providedIn: 'root' })
export class LoggerService {
  private isCordova: any;
  private offlineLog: LogItem[] = [];
  private uid: any;
  private versionCode: any;
  private versionNumber: any;
  private packageName: any;
  private appName: any;
  private plt: any;
  private logCollectionName = 'SystemLog';
  private loading?: HTMLIonLoadingElement;
  private LOGLEVEL: 'NONE' | 'OFFLINE' | 'ANALYTICS' | 'ONLINE' = 'ONLINE';
  private email: any;
  private analytics: Analytics = inject(Analytics);

  constructor(
    private storage: StorageService,
    private alertController: AlertController,
    private toastController: ToastController,
    private loadingController: LoadingController,
    private platform: Platform,
    private afs: AngularFirestore,
    private translate: TranslatePipe,
    private router: Router
  ) {
    this.platform.ready().then(async () => {
      await this.init();
      this.plt = this.platform.platforms().join(',');
      this.isCordova = this.platform.is('cordova');
    });
  }

  private init(): Promise<void> {
    return new Promise(async resolve => {
      try {
        const offlineLog = await this.loadLog();
        if (offlineLog) {
          this.offlineLog = offlineLog;
        } else {
          this.offlineLog = [];
        }
        resolve();
      } catch (error) {
        this.offlineLog = [];
        resolve();
      }
    });
  }

  setUserId(uid: any) {
    this.uid = uid;
    setUserId(this.analytics, this.uid);
    if (this.LOGLEVEL === 'ONLINE') {
      this.uploadOldLog();
    }
  }

  getUserId() {
    return this.uid || null;
  }

  setVersionCode(versionCode: any) {
    this.versionCode = versionCode;
  }

  getVersionCode(): any {
    return this.versionCode || null;
  }

  setVersionNumber(versionNumber: any) {
    this.versionNumber = versionNumber;
  }

  getVersionNumber(): any {
    return this.versionNumber || environment.version || null;
  }

  setPackageName(packageName: any) {
    this.packageName = packageName;
  }

  getPackageName(): any {
    return this.packageName || null;
  }

  setAppName(appName: any) {
    this.appName = appName;
  }

  getAppName(): any {
    return this.appName || null;
  }

  setEmail(email: any) {
    this.email = email;
  }

  getEmail(): any {
    return this.email || null;
  }

  private logAnalytics(eventName: any, params: any = {}) {
    if (this.getUserId() !== null) {
      params.uid = this.getUserId();
      params.when = new Date().toISOString();
      params.versionNumber = this.getVersionNumber();
      params.versioncode = this.getVersionCode();
      params.packageName = this.getPackageName();
      logEvent(this.analytics, eventName, params);
      console.log(eventName);
    }
  }

  async showToast(message: any = '', color: any = 'primary', duration: number = 1000, position: 'top' | 'bottom' | 'middle' = 'bottom') {
    const toast = await this.toastController.create({
      message: this.translate.transform(message),
      color: color,
      duration: duration,
      position: position,
      cssClass: 'ion-text-center',
      mode: 'ios',
    });
    await toast.present();
  }

  async handleError(error: any, show = false) {
    console.log(' ----- ERROR ----- ');
    console.log(error);
    const errorCodeText = this.parseErrorCode(error.code);
    const header = errorCodeText?.header || error.code || error.header || 'ERROR';
    const title = errorCodeText?.title || error.msg || error.message || error.data || error.reason || error || '';
    const subtitle = error.error || '';
    await this.addLogItem(title, error, 'error');
    if (show) {
      const alert = await this.alertController.create({
        header: this.translate.transform(header),
        subHeader: this.translate.transform(title),
        message: this.translate.transform(subtitle),
        backdropDismiss: false,
        buttons: [this.translate.transform('OK')],
      });
      await alert.present();
      await alert.onDidDismiss();
    }
  }

  parseErrorCode(errorCode: string): any {
    const errorCodeTexts: any = {
      'permission-denied': {
        header: 'ERROR',
        title: 'PERMISSION_DENIED',
      },
      '': {
        header: 'ERROR',
        title: '',
      },
    };
    return errorCodeTexts[errorCode] || null;
  }

  async presentLoading(message: any = undefined): Promise<void> {
    await this.dismissLoading();
    return new Promise(async resolve => {
      this.loading = await this.loadingController.create({ message: message, backdropDismiss: false });
      await this.loading.present();
      resolve();
    });
  }

  dismissLoading(): Promise<void> {
    return new Promise(async resolve => {
      if (this.loading) {
        await this.loading.dismiss();
      }
      resolve();
    });
  }

  presentPasswordALert(header: any, message: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        header: header,
        message: message,
        backdropDismiss: false,
        inputs: [
          {
            name: 'password',
            type: 'password',
          },
        ],
        buttons: [
          {
            text: this.translate.transform('CANCEL'),
            handler: () => {
              reject();
            },
          },
          {
            text: this.translate.transform('OK'),
            handler: data => {
              resolve(data.password);
            },
          },
        ],
      });
      await alert.present();
    });
  }

  presentConfirmALert(header: any, message: any, rejectText: any = null, resolveText: any = null): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        header: this.translate.transform(header),
        message: this.translate.transform(message),
        cssClass: message ? 'confirm-alert-dialog' : undefined,
        backdropDismiss: false,
        buttons: [
          {
            text: rejectText || this.translate.transform('CANCEL'),
            cssClass: 'cancel-button',
            handler: () => {
              reject();
            },
          },
          {
            text: resolveText || this.translate.transform('YES'),
            cssClass: 'delete-button',
            handler: () => {
              resolve();
            },
          },
        ],
      });
      await alert.present();
    });
  }

  presentConfirmDeleteALert(message: any = null): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const alert = await this.alertController.create({
        header: this.translate.transform('DELETE_CONFIRM_HEADER'),
        message: message ? this.translate.transform(message) + '<br><br>' + this.translate.transform('DELETE_CONFIRM_MESSAGE') : this.translate.transform('DELETE_CONFIRM_MESSAGE'),
        backdropDismiss: false,
        inputs: [
          {
            name: 'delete',
            type: 'text',
            placeholder: this.translate.transform('DELETE'),
          },
        ],
        buttons: [
          {
            text: this.translate.transform('NO'),
            handler: () => {
              reject();
            },
          },
          {
            text: this.translate.transform('YES'),
            cssClass: 'delete-button',
            handler: data => {
              if (data.delete === this.translate.transform('DELETE')) {
                resolve();
              } else {
                reject();
              }
            },
          },
        ],
      });
      await alert.present();
    });
  }

  getSystemLog(uid: any, startDate: Date, endDate: Date, onlyErrors: boolean = false) {
    return this.afs
      .collection(this.logCollectionName, ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('uid', '==', uid);
        query = query.where('timeStamp', '<', endDate.toISOString());
        query = query.where('timeStamp', '>=', startDate.toISOString());
        if (onlyErrors) {
          query = query.where('type', '==', 'error');
        }
        query = query.orderBy('timeStamp', 'desc');
        return query;
      })
      .valueChanges();
  }

  private async uploadOldLog() {
    if (this.offlineLog.length > 0) {
      let error = null;
      for (var i = 0, len = this.offlineLog.length; i < len; ++i) {
        const oldLogItem = this.offlineLog[i];
        const newLogItem = {
          id: null,
          title: oldLogItem.title,
          subtitle: oldLogItem.subtitle,
          type: oldLogItem.type,
          uid: this.getUserId(),
          versionNumber: this.getVersionNumber(),
          versionCode: this.getVersionCode(),
          packageName: this.getPackageName(),
          cordova: this.isCordova,
          platform: this.plt,
          currentUrl: this.router.url,
          email: this.email,
          appName: this.getAppName(),
          timeStamp: new Date(oldLogItem.timeStamp).toISOString(),
        };
        try {
          await this.addSystemLog(newLogItem, false);
        } catch (e) {
          error = e;
        }
      }
      if (!error) {
        this.offlineLog = [];
        this.storeLog();
      }
    }
  }

  async addLogItem(title: any = null, subtitle: any = null, type: any = 'info', timeStamp: any = null) {
    const item = new LogItem(null, title, subtitle, type, this.getUserId(), this.getVersionNumber(), this.getVersionCode(), this.getPackageName(), this.isCordova, this.plt, timeStamp, this.email, this.router.url as any, this.getAppName());
    try {
      await this.addSystemLog(item);
    } catch (error) {
      console.error(error);
    }
  }

  private addSystemLog(item: LogItem, isNew: boolean = true): Promise<void> {
    // console.log(JSON.stringify(item));
    return new Promise(async (resolve, reject) => {
      let error = null;
      let analytics = false;
      if (this.LOGLEVEL === 'NONE') {
        resolve();
        return;
      }
      if (this.LOGLEVEL === 'ANALYTICS') {
        analytics = true;
      }
      if (this.LOGLEVEL === 'ONLINE' && item.uid !== null) {
        analytics = true;
        try {
          item.id = this.afs.createId();
          await this.afs.collection(this.logCollectionName).doc(item.id).set(Object.assign({}, item));
        } catch (e) {
          error = e;
        }
      }
      if (this.LOGLEVEL === 'OFFLINE' || (error && isNew) || item.uid === null) {
        this.pushOfflineLog(item);
      }
      if (analytics) {
        this.logAnalytics(item.type === 'error' ? 'error_happened' : item.title.toLowerCase());
      }
      if (error) {
        reject(error);
        return;
      }
      resolve();
      return;
    });
  }

  private async pushOfflineLog(item: LogItem) {
    try {
      this.offlineLog.push(item);
      await this.storeLog();
    } catch (error) {
      console.error(error);
    }
  }

  private loadLog(): Promise<LogItem[]> {
    return this.storage.get('log-array');
  }

  private storeLog(): Promise<LogItem[]> {
    return this.storage.set('log-array', this.offlineLog);
  }
}
